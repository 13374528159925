export class UserDTO {

  public id = 0;
  public firstName = "";
  public lastName = "";
  public email = "";
  public phone = "";
  public orgaId = 0;

  constructor(init?: Partial<UserDTO>) {
    if (init === undefined) return;
    Object.assign(this, init);
  }
}
